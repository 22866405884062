<template>
  <tbody v-if="Object.keys(form).length">
    <tr class="text-right">
      <td colspan="4" class="text-right font-weight-bold h6">Ara Toplam</td>
      <td colspan="2" class="text-right font-weight-bold h6">
        {{ form.kur_sepet_toplam.ara_toplam | cur }} {{ form.secilen_kur }}
      </td>
    </tr>
    <tr class="text-right">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">
        KDV Toplam
      </td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">
        {{ form.kur_sepet_toplam.kdv_toplam | cur }} {{ form.secilen_kur }}
      </td>
    </tr>
    <tr
      class="text-right"
      v-if="parseFloat(form.kur_sepet_toplam.kargo_toplam) > 0"
    >
      <td colspan="4" class="text-right font-weight-bold h6 border-0">
        Kargo Toplam
      </td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">
        {{ form.kur_sepet_toplam.kargo_toplam | cur }} {{ form.secilen_kur }}
      </td>
    </tr>
    <tr class="text-right" v-if="form.kur_sepet_toplam.kargo_ekstra_toplam > 0">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">
        Kapıda Ödeme Ekstra
      </td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">
        {{ form.kur_sepet_toplam.kargo_ekstra_toplam | cur }}
        {{ form.secilen_kur }}
      </td>
    </tr>
    <tr class="text-right" v-if="form.kur_sepet_toplam.indirim_toplam > 0">
      <td colspan="4" class="text-right font-weight-bold h6 border-0">
        İndirim Toplam ({{ form.indirim_kuponu.kod }})
      </td>
      <td colspan="2" class="text-right font-weight-bold h6 border-0">
        {{ form.kur_sepet_toplam.indirim_toplam | cur }} {{ form.secilen_kur }}
      </td>
    </tr>
    <tr class="text-right">
      <td
        colspan="4"
        class="text-right font-weight-bold h5 border-0 text-primary"
      >
        GENEL TOPLAM
      </td>
      <td
        colspan="2"
        class="text-right font-weight-bold h5 border-0 text-primary"
      >
        {{ form.kur_sepet_toplam.genel_toplam | cur }} {{ form.secilen_kur }}
      </td>
    </tr>
  </tbody>
</template>

<script>
import { defineComponent } from "@vue/composition-api";

export default defineComponent({
  props: {
    form: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const expo = {};

    return { ...expo };
  },
});
</script>

<style lang="scss" scoped></style>
